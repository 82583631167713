var dashboard = (function(){
	
	var dashboardTabCurrentHealthCheck = document.getElementById("dashboardTabCurrentHealthCheck"),
		dashboardTabPlansProjects = document.getElementById("dashboardTabPlansProjects"),
		dashboardTabDocumentsDownloads = document.getElementById("dashboardTabDocumentsDownloads"),
		dashboardTabPreviousHealthCheck = document.getElementById("dashboardTabPreviousHealthCheck"),
		dashboardTabAdminTools = document.getElementById("dashboardTabAdminTools"),
		allTabs = [dashboardTabPlansProjects, dashboardTabDocumentsDownloads, dashboardTabPreviousHealthCheck, dashboardTabAdminTools],
		dashboardCurrentHealthCheck = document.getElementById("dashboardCurrentHealthCheck"),
		dashboardPlansProjects = document.getElementById("dashboardPlansProjects"),
		dashboardDocumentsDownloads = document.getElementById("dashboardDocumentsDownloads"),
		dashboardPreviousHealthCheck = document.getElementById("dashboardPreviousHealthCheck"),
		dashboardAdminTools = document.getElementById("dashboardAdminTools"),
		allSections = [dashboardCurrentHealthCheck, dashboardPlansProjects, dashboardDocumentsDownloads, dashboardPreviousHealthCheck, dashboardAdminTools],
		initiationDeadlineDateBtn = document.getElementById("initiationDeadlineDateBtn"),
		liveReflectionDeadlineDateBtn = document.getElementById("liveReflectionDeadlineDateBtn"),
		teamReviewConsensusDeadlineDateBtn = document.getElementById("teamReviewConsensusDeadlineDateBtn"),
		livePlanningStatusDeadlineDateBtn = document.getElementById("livePlanningStatusDeadlineDateBtn"),
		newHealthCheckStatusDeadlineDateBtn = document.getElementById("newHealthCheckStatusDeadlineDateBtn"),
		projectProgressStatusDeadlineDateBtn = document.getElementById("projectProgressStatusDeadlineDateBtn"),
		projectPlanningDeadlineDateBtn = document.getElementById("projectPlanningDeadlineDateBtn"),
		teamReviewPrioritisationDeadlineDateBtn = document.getElementById("teamReviewPrioritisationDeadlineDateBtn"),
		allDateBtn = [initiationDeadlineDateBtn, liveReflectionDeadlineDateBtn, teamReviewConsensusDeadlineDateBtn, livePlanningStatusDeadlineDateBtn, newHealthCheckStatusDeadlineDateBtn, projectProgressStatusDeadlineDateBtn, projectPlanningDeadlineDateBtn, teamReviewPrioritisationDeadlineDateBtn],
		initiationDeadlineDateEntry = document.getElementById("initiationDeadlineDateEntry"),
		liveReflectionDeadlineDateEntry = document.getElementById("liveReflectionDeadlineDateEntry"),
		teamReviewConsensusDeadlineDateEntry = document.getElementById("teamReviewConsensusDeadlineDateEntry"),
		livePlanningStatusDeadlineDateEntry = document.getElementById("livePlanningStatusDeadlineDateEntry"),
		newHealthCheckStatusDeadlineDateEntry = document.getElementById("newHealthCheckStatusDeadlineDateEntry"),
		projectProgressStatusDeadlineDateEntry = document.getElementById("projectProgressStatusDeadlineDateEntry"),
		projectPlanningDeadlineDateEntry = document.getElementById("projectPlanningDeadlineDateEntry"),
		teamReviewPrioritisationDeadlineDateEntry = document.getElementById("teamReviewPrioritisationDeadlineDateEntry"),
		allDateEntry = [initiationDeadlineDateEntry, liveReflectionDeadlineDateEntry, teamReviewConsensusDeadlineDateEntry, livePlanningStatusDeadlineDateEntry, newHealthCheckStatusDeadlineDateEntry, projectProgressStatusDeadlineDateEntry, projectPlanningDeadlineDateEntry, teamReviewPrioritisationDeadlineDateEntry],
		allDateEntryACF,
		submitACF = document.getElementsByClassName("acf-form-submit"),
		initiationStatus = document.getElementById("initiationStatus"),
		liveReflectionStatus = document.getElementById("liveReflectionStatus"),
		teamReviewConsensusStatus = document.getElementById("teamReviewConsensusStatus"),
		livePlanningStatus = document.getElementById("livePlanningStatus"),
		newHealthCheckStatus = document.getElementById("newHealthCheckStatus"),
		projectProgressStatus = document.getElementById("projectProgressStatus"),
		projectPlanningStatus = document.getElementById("projectPlanningStatus"),
		teamReviewPrioritisationStatus = document.getElementById("teamReviewPrioritisationStatus"),
		allStatus = [initiationStatus, liveReflectionStatus, teamReviewConsensusStatus, livePlanningStatus, newHealthCheckStatus, projectProgressStatus, projectPlanningStatus, teamReviewPrioritisationStatus],	
		allStatusACF;
		
	var displayTab = function(section) {
		for (var i=0; i < allTabs.length; i++){
			allTabs[i].className = '';
			allSections[i].style.display = "none";
		}
		
		section.style.display = "block";
	}
	
	var displayEntry = function(entryId){
		for (var i=0; i < allDateBtn.length; i++){
			allDateBtn[i].style.display = "block";
			allDateEntry[i].style.display = "none";
		}
		
		var entry = document.getElementById(entryId+"Entry"),
			btn = document.getElementById(entryId+"Btn");
		
		btn.style.display = "none";
		entry.style.display = "block";
	}
	
	var formatDate = function (data){
		function appendLeadingZeroes(n){
			if(n <= 9){
				return "0" + n;
			}
			return n;
		}
		
		data = new Date(data);
		
		var formatted_date = appendLeadingZeroes(data.getDate()) + "/" + appendLeadingZeroes(data.getMonth() + 1) + "/" + data.getFullYear();
		return formatted_date;
	}
	
	var formatDateACF = function(date){
		date = date.split("/");
		var newformat = []
		newformat.push(date[2]);
		newformat.push(date[1]);
		newformat.push(date[0]);
		
		return newformat.join("");
	}
	
	var updateDeadline = function(data, element){
		var date = formatDate(data)
		element.innerHTML = date;
		for(var i=0; i < allDateBtn.length; i++){
			if(element == allDateBtn[i]){
				break;
			}
		}
		
		allDateEntryACF[i].value = date;
		var parent = allDateEntryACF[i].parentElement;
		parent.firstElementChild.value = formatDateACF(date);
		
		submitACF[0].style.display = "block";
	}
	
	var updateLinks = function(){
		var allWaiting = document.getElementsByClassName('waiting'),
			allStages = document.getElementsByClassName('stageContainer');
		
		console.log("allStages: " + allStages.length);
		for (var i=0; i < allStages.length; i++){
			var links = allStages[i].getElementsByTagName('a');
			for (var c=0; c < links.length; c++){
				links[c].style.pointerEvents = "auto";
			}
		}
		
		console.log("allWaiting: " + allWaiting.length);
		for (var i=0; i < allWaiting.length; i++){
			var links = allWaiting[i].getElementsByTagName('a');
			for (var c=0; c < links.length; c++){
				links[c].style.pointerEvents = "none";
			}
		}
	}
	
	var changeStatus = function(element){
		var status;
		if(element.parentElement.parentElement.classList.contains("completed")){
			element.parentElement.parentElement.classList.remove("completed");
			element.parentElement.parentElement.classList.add("waiting");
			status = "waiting";
		}else if(element.parentElement.parentElement.classList.contains("inProgress")){
			element.parentElement.parentElement.classList.remove("inProgress");
			element.parentElement.parentElement.classList.add("completed");
			status = "completed";
		}else if(element.parentElement.parentElement.classList.contains("waiting")){
			element.parentElement.parentElement.classList.remove("waiting");
			element.parentElement.parentElement.classList.add("inProgress");
			status = "inProgress";
		}
		
		for(var i=0; i < allStatus.length; i++){
			if(element == allStatus[i]){
				break;
			}
		}
		
		allStatusACF[i].value = status;
		
		submitACF[0].style.display = "block";
		
		updateLinks();
	}
	
	var init = function(){
		
		allDateEntryACF = document.getElementsByClassName("hasDatepicker");
		allStatusACF = document.getElementsByTagName("select");
		
		if( document.body.contains(dashboardTabCurrentHealthCheck) ){
			allTabs.unshift(dashboardTabCurrentHealthCheck);
			allSections.unshift(dashboardCurrentHealthCheck);
		}
		
		if( document.body.contains(dashboardAdminTools) ){
			allTabs.push(dashboardTabAdminTools);	
			allSections.push(dashboardAdminTools);
		}
		
		allTabs = allTabs.filter(function (el) {
			return el != null;
		});
		
		if( document.body.contains(dashboardTabCurrentHealthCheck) ){
			displayTab(dashboardCurrentHealthCheck);
			dashboardTabCurrentHealthCheck.classList.add("active");
			dashboardTabCurrentHealthCheck.addEventListener('click', function (event) {
				displayTab(dashboardCurrentHealthCheck);
				dashboardTabCurrentHealthCheck.classList.add("active");
			});
		} else {
			displayTab(dashboardPlansProjects);
			dashboardTabPlansProjects.classList.add("active");
		}
		dashboardTabPlansProjects.addEventListener('click', function (event) {
			displayTab(dashboardPlansProjects);
			dashboardTabPlansProjects.classList.add("active");
		});
		dashboardTabDocumentsDownloads.addEventListener('click', function (event) {
			displayTab(dashboardDocumentsDownloads);
			dashboardTabDocumentsDownloads.classList.add("active");
		});
		dashboardTabPreviousHealthCheck.addEventListener('click', function (event) {
			displayTab(dashboardPreviousHealthCheck);
			dashboardTabPreviousHealthCheck.classList.add("active");
		});
		if( document.body.contains(dashboardTabAdminTools) ){
			dashboardTabAdminTools.addEventListener('click', function (event) {
				displayTab(dashboardAdminTools);
				dashboardTabAdminTools.classList.add("active");
			});
		}
		
		initiationDeadlineDateBtn.addEventListener('click', function (event) {
			displayEntry("initiationDeadlineDate");
		});
		liveReflectionDeadlineDateBtn.addEventListener('click', function (event) {
			displayEntry("liveReflectionDeadlineDate");
		});
		teamReviewConsensusDeadlineDateBtn.addEventListener('click', function (event) {
			displayEntry("teamReviewConsensusDeadlineDate");
		});
		livePlanningStatusDeadlineDateBtn.addEventListener('click', function (event) {
			displayEntry("livePlanningStatusDeadlineDate");
		});
		newHealthCheckStatusDeadlineDateBtn.addEventListener('click', function (event) {
			displayEntry("newHealthCheckStatusDeadlineDate");
		});
		projectProgressStatusDeadlineDateBtn.addEventListener('click', function (event) {
			displayEntry("projectProgressStatusDeadlineDate");
		});
		projectPlanningDeadlineDateBtn.addEventListener('click', function (event) {
			displayEntry("projectPlanningDeadlineDate");
		});
		teamReviewPrioritisationDeadlineDateBtn.addEventListener('click', function (event) {
			displayEntry("teamReviewPrioritisationDeadlineDate");
		});
		
		
		initiationDeadlineDateEntry.addEventListener('change', function (event) {
			updateDeadline(event.target.value, initiationDeadlineDateBtn);
		});
		liveReflectionDeadlineDateEntry.addEventListener('change', function (event) {
			updateDeadline(event.target.value, liveReflectionDeadlineDateBtn);
		});
		teamReviewConsensusDeadlineDateEntry.addEventListener('change', function (event) {
			updateDeadline(event.target.value, teamReviewConsensusDeadlineDateBtn);
		});
		livePlanningStatusDeadlineDateEntry.addEventListener('change', function (event) {
			updateDeadline(event.target.value, livePlanningStatusDeadlineDateBtn);
		});
		newHealthCheckStatusDeadlineDateEntry.addEventListener('change', function (event) {
			updateDeadline(event.target.value, newHealthCheckStatusDeadlineDateBtn);
		});
		projectProgressStatusDeadlineDateEntry.addEventListener('change', function (event) {
			updateDeadline(event.target.value, projectProgressStatusDeadlineDateBtn);
		});
		projectPlanningDeadlineDateEntry.addEventListener('change', function (event) {
			updateDeadline(event.target.value, projectPlanningDeadlineDateBtn);
		});
		teamReviewPrioritisationDeadlineDateEntry.addEventListener('change', function (event) {
			updateDeadline(event.target.value, teamReviewPrioritisationDeadlineDateBtn);
		});
		
		initiationStatus.addEventListener('click', function (event) {
			changeStatus(initiationStatus);
		});
		liveReflectionStatus.addEventListener('click', function (event) {
			changeStatus(liveReflectionStatus);
		});
		teamReviewConsensusStatus.addEventListener('click', function (event) {
			changeStatus(teamReviewConsensusStatus);
		});
		livePlanningStatus.addEventListener('click', function (event) {
			changeStatus(livePlanningStatus);
		});
		newHealthCheckStatus.addEventListener('click', function (event) {
			changeStatus(newHealthCheckStatus);
		});
		projectProgressStatus.addEventListener('click', function (event) {
			changeStatus(projectProgressStatus);
		});
		projectPlanningStatus.addEventListener('click', function (event) {
			changeStatus(projectPlanningStatus);
		});
		teamReviewPrioritisationStatus.addEventListener('click', function (event) {
			changeStatus(teamReviewPrioritisationStatus);
		});
		
		updateLinks();
	}
	
	return {
		init : init
	};
	
})();