function getRandomColor() {
	var letters = '0123456789ABCDEF';
	var color = '#';
	for (var i = 0; i < 6; i++) {
		color += letters[Math.floor(Math.random() * 16)];
	}
	return color;
}

function run(){
	/*
	if( !$(".page-template-template-survey-intro")[0] || !$(".page-template-template-dashboard")[0]){
		jQuery('form#health-check-form :submit').click(function(event){
			event.preventDefault();
		});
	}
	*/
	if ( $( "form#health-check-form :input" ).length ) {
		
		jQuery('.health-check-form-submit').click(function(event){
			event.preventDefault();
			
			var buttonID = event.target.id;
			var buttonhref = $(this).attr('href');
			var form_data = {'action' : 'acf/validate_save_post'};
			jQuery('form#health-check-form :input').each(function(){
				form_data[jQuery(this).attr('name')] = jQuery(this).val();
			});
			form_data.action = 'save_my_data';
			jQuery.post(my_ajax_object.ajax_url, form_data).done(function(save_data){
				window.location.href = buttonhref;
			});
		});
	}
	
	
	//	initClamp();
	//	initTouchMenu();
	if (typeof acf !== 'undefined') {
		acf.unload.active = false;
	}

	//rangeslider.js
	function valueOutput(element) {
		var value = element.value;
		$output.val(value);
		
		if ( value == 0 ){
			$feedback.html('<span>Not Answered:</span> Slide the handle to indicate how strongly you agree with the above statement in relation to your sport.');
		} else if ( value == 1 ) {
			$feedback.html('<span>Red:</span> Strategy does not exist or is not working. Solutions to challenges not agreed, planned or activated.');
		} else if ( value > 1 && value < 4 ) {
			$feedback.html('<span>Amber:</span> Solutions to challenges identified, action plans under way with signs of progress being made.');
		} else if ( value > 3 && value < 7 ) {
			$feedback.html('<span>Green:</span> Working well. Fully functional, up to date and fit for purpose. High confidence based on evidence. Challenges identified, action taken and solutions working.');
		} else if ( value > 6 ) {
			$feedback.html('<span>Gold:</span> Outstanding and a match for the international benchmark. A potential source of learning and inspiration to others. World Class.');
		}
		
		if ( $( ".survey-submit-button" ).length ) {
			$( ".survey-submit-button" ).hide();
		}
		
		if ( $( "#post" ).length && $( ".acf-input-wrap" ).length ) {
			$( "#acf-data" ).val( $( "#"+acfField ).val() );
		}
		
		if ( value != 0  && $( ".near-complete" ).length || value != 0  && $( ".complete" ).length) {
			$( ".survey-submit-button" ).show();
		}else{
			$( ".survey-submit-button" ).hide();
		}
	}
	
	if($( "#slider-feedback" ).length){
		var $document   = $(document),
			$inputRange = $('input[type="range"]'),
			output = $( ".acf-input-wrap" ).children();
			$output = $( "#"+output[0].id );
			$feedback = $('#slider-feedback');
		
		if ( $( "#post" ).length && $( ".acf-input-wrap" ).length ) {
			var acfField = $(".acf-input-wrap").children("input").attr("id");
			$( "#acf-data" ).val( $( "#"+acfField ).val() );    
		}
	
		for (var i = $inputRange.length - 1; i >= 0; i--) {
			valueOutput($inputRange[i]);
		}
		$document.on('input', 'input[type="range"]', function(e) {
			valueOutput(e.target);
		});
		// end
		
		$inputRange.rangeslider({
			polyfill: false 
		});
	}
	
	//Comments
	function commentsValueOutput(element, target) {
		var value = element.value;
		target.val(value);
	}
	
	//During Survey
	if($( "#strengthsField" ).length){
		
		var $document   = $(document),
			$acfCommentsField = $('.acf-input textarea'),
			$strengthsField = $('#strengthsField'),
			acfStrengthsFieldId = $acfCommentsField[0].id,
			$acfStrengthsField = $( '#' + acfStrengthsFieldId ),
			$areasForDevelopmentField = $('#areasForDevelopmentField'),
			acfAreasForDevelopmentFieldId = $acfCommentsField[1].id,
			$acfAreasForDevelopmentField = $( '#' + acfAreasForDevelopmentFieldId ),
			$potentialActionsField = $('#potentialActionsField'),
			acfPotentialActionsFieldId = $acfCommentsField[2].id,
			$acfPotentialActionsField = $( '#' + acfPotentialActionsFieldId );
			
		if ( $( "#post" ).length && $( ".acf-input-wrap" ).length ) {
			var $acfDataStrengths = $('#acf-data_strengths'),
				$acfDataAreasForDevelopment = $('#acf-data_areas_for_development'),
				$acfDataPotentialAction = $('#acf-data_potential_action');
				
			$acfDataStrengths.val( $acfStrengthsField.val() );
			$acfDataAreasForDevelopment.val( $areasForDevelopmentField.val() );
			$acfDataPotentialAction.val( $acfPotentialActionsField.val() );
		}
			  
		//$strengthsField.val( $acfStrengthsField.val() );
		$strengthsField.bind('input  propertychange', function(e) {
			commentsValueOutput(e.target, $acfStrengthsField);
			if ( $( "#post" ).length && $( ".acf-input-wrap" ).length ) {
				commentsValueOutput(e.target, $acfDataStrengths);
			}
		});
		
		//$areasForDevelopmentField.val( $acfAreasForDevelopmentField.val() );
		$areasForDevelopmentField.bind('input  propertychange', function(e) {
			commentsValueOutput(e.target, $acfAreasForDevelopmentField);
			if ( $( "#post" ).length && $( ".acf-input-wrap" ).length ) {
				commentsValueOutput(e.target, $acfDataAreasForDevelopment);
			}
		});

		//$potentialActionsField.val( $acfPotentialActionsField.val() );
		$potentialActionsField.bind('input  propertychange', function(e) {
			commentsValueOutput(e.target, $acfPotentialActionsField);
			if ( $( "#post" ).length && $( ".acf-input-wrap" ).length ) {
				commentsValueOutput(e.target, $acfDataPotentialAction);
			}
		});
		// end
	}
	
	//during Survey review
	if($( ".review_scores" ).length){
		
		var $document = $(document);
		
		//get every review score range input
		var allEffortRangeInputs = document.getElementsByClassName('review_scores_effort');
		for (var i=0; i < allEffortRangeInputs.length; i++) {
			window[allEffortRangeInputs[i].id] = $( '#' + allEffortRangeInputs[i].id);		
		}
		
		var allImpactRangeInputs = document.getElementsByClassName('review_scores_impact');
		for (var i=0; i < allImpactRangeInputs.length; i++) {
			window[allImpactRangeInputs[i].id] = $( '#' + allImpactRangeInputs[i].id);
		}	
			
		//get every review score text input
		var $acfReviewFieldGroups = $('.acf-fields.-top.-border'),
			acfAllEffortRangeInputsID = [],
			acfAllImpactRangeInputsID = [];
			
		for (var i=0; i < ($acfReviewFieldGroups.length); i++) {
			var tempAcfReviewFieldGroup = document.getElementsByClassName("-border")[i];
			acfAllEffortRangeInputsID.push(tempAcfReviewFieldGroup.childNodes[0].childNodes[3].childNodes[1].childNodes[0].id);
			acfAllImpactRangeInputsID.push(tempAcfReviewFieldGroup.childNodes[2].childNodes[3].childNodes[1].childNodes[0].id);
			
			window["acf" + allEffortRangeInputs[i].id] = $( '#' + acfAllEffortRangeInputsID[i] );
			window["acf" + allImpactRangeInputs[i].id] = $( '#' + acfAllImpactRangeInputsID[i] );
			
// 			window[allEffortRangeInputs[i].id].val(  window["acf" + allEffortRangeInputs[i].id].val() );
			window[allEffortRangeInputs[i].id].bind('input propertychange', function(e) {
				commentsValueOutput(e.target, window["acf" + this.id]);
			});
			
			//window[allImpactRangeInputs[i].id].val( window["acf" + allImpactRangeInputs[i].id].val() );
			window[allImpactRangeInputs[i].id].bind('input propertychange', function(e) {
				commentsValueOutput(e.target, window["acf" + this.id]);
			});
		}
	}

	//Results - display comments
	if( $( "#pathway-health-check-results-summary" ).length || $( "#pathway-health-check-comparison-tool" ).length ){
		$statement = $(".statement");
		$statement.on('click',$statement, function(e) {
			//get id
			var targetName = $(this).attr('name');
			var statementCount = parseInt(targetName[targetName.length -1]) - 1;
			
			//get comment id
			var strengthsClass = targetName + "_strengths",
				areasForDevelopmentClass = targetName + "_areas_for_development",
				potentialActionsClass = targetName + "_potential_actions";
				
			//hide all comments
			$( ".comments" ).hide();
			$( ".comments p" ).removeClass( "displayComment" );
			//display comment using id
			$( "."+strengthsClass ).addClass( "displayComment" );
			//display comment using id
			$( "."+areasForDevelopmentClass ).addClass( "displayComment" );
			//display comment using id
			$( "."+potentialActionsClass ).addClass( "displayComment" );
			//remove highlight to all statements
			$statement.removeClass( "commentHighlight" );
			//add highlight to clicked statement
			$( "."+targetName ).addClass( "commentHighlight" );
			var topCalc = 148 + ( statementCount * 177 );
			$(".comments").css({"top": topCalc+"px"});
			$( ".comments" ).show();
		});
	}


	//Comparison Tool - Add selected health checks to URL
	if( $( "#select-health-check-1" ).length ){
		//get select
		$compareResultsID1 = $("select#select-health-check-1");
		$compareResultsID2 = $("select#select-health-check-2");
		$compareButton = $("#comparison-tool-button");
		
		$compareResultsID1.unbind("change").change(function(){
        	
        	var selectedID1 = $(this).children("option:selected").val(),
        		selectedID2 = $compareResultsID2.children("option:selected").val(),
        		newURL = comparisonTool + '?compareResultsID1=' + selectedID1 + '&compareResultsID2=' + selectedID2;
        	
        	if(selectedID1 == '' || selectedID2 == '' ){
	        	$compareButton.attr("href", comparisonTool);
	        }else{
		        $compareButton.attr("href", newURL);
	        }
		});
		
		$compareResultsID2.unbind("change").change(function(){
        	var selectedID1 = $compareResultsID1.children("option:selected").val(),
        		selectedID2 = $(this).children("option:selected").val(),
        		newURL = comparisonTool + '?compareResultsID1=' + selectedID1 + '&compareResultsID2=' + selectedID2;
        	
        	if(selectedID1 == '' || selectedID2 == '' ){
	        	$compareButton.attr("href", comparisonTool);
	        }else{
		        $compareButton.attr("href", newURL);
	        }
		});
	}

	//Comparison Tool - Nav
	if( $( "#pathway-health-check-comparison-tool" ).length || $( "#pathway-health-check-results-summary" ).length ){
		$comparisonNavPrevious = $("#comparison-nav-previous");
		$comparisonNavPrevious.unbind("click").click(function(e) {
			
			if($( ".review_scores" ).length){
				var buttonID = event.target.id;
				var buttonhref = $(this).attr('href');
				var form_data = {'action' : 'acf/validate_save_post'};
				$('form#health-check-form :input').each(function(){
					form_data[jQuery(this).attr('name')] = jQuery(this).val();
				});
				form_data.action = 'save_my_data';
				jQuery.post(my_ajax_object.ajax_url, form_data).done(function(save_data){
					//window.location.href = buttonhref;
				});
			}
			
			$( ".comments" ).hide();
			$( ".comments p" ).removeClass( "displayComment" );
			$(".statement").removeClass( "commentHighlight" );
			if($('.result1').is(':visible')){
				$('.result1').hide();
				$('.result4').show();
				return;
			}
			
			if($('.result2').is(':visible')){
				$('.result2').hide();
				$('.result1').show();
				return;
			}
			
			if($('.result3').is(':visible')){
				$('.result3').hide();
				$('.result2').show();
				return;
			}
			
			if($('.result4').is(':visible')){
				$('.result4').hide();
				$('.result3').show();
				return;
			}
		});
		
		$comparisonNavNext = $("#comparison-nav-next");
		$comparisonNavNext.unbind("click").click(function(e) {
			
			if($( ".review_scores" ).length){
				var buttonID = event.target.id;
				var buttonhref = $(this).attr('href');
				var form_data = {'action' : 'acf/validate_save_post'};
				$('form#health-check-form :input').each(function(){
					form_data[jQuery(this).attr('name')] = jQuery(this).val();
				});
				form_data.action = 'save_my_data';
				jQuery.post(my_ajax_object.ajax_url, form_data).done(function(save_data){
					//window.location.href = buttonhref;
				});
			}
			
			$( ".comments" ).hide();
			$( ".comments p" ).removeClass( "displayComment" );
			$(".statement").removeClass( "commentHighlight" );
			if($('.result1').is(':visible')){
				$('.result1').hide();
				$('.result2').show();
				return;
			}
			
			if($('.result2').is(':visible')){
				$('.result2').hide();
				$('.result3').show();
				return;
			}
			
			if($('.result3').is(':visible')){
				$('.result3').hide();
				$('.result4').show();
				return;
			}
			
			if($('.result4').is(':visible')){
				$('.result4').hide();
				$('.result1').show();
				return;
			}
		});
	}
	
/*
	if( $( ".review-submit-button" ).length ){
		$reviewSubmitButton = $(".review-submit-button");
		$reviewSubmitButton.unbind("click").click(function(e) {
			event.preventDefault();
			
			if($( ".review_scores" ).length){
				$reviewComplete = $("input[type='checkbox']");
				$reviewComplete.val($reviewComplete.prop('checked'));
				
				var buttonID = event.target.id;
				var buttonhref = $(this).attr('href');
				var form_data = {'action' : 'acf/validate_save_post'};
				$('form#health-check-form :input').each(function(){
					form_data[jQuery(this).attr('name')] = jQuery(this).val();
				});
				form_data.action = 'save_my_data';
				jQuery.post(my_ajax_object.ajax_url, form_data).done(function(save_data){
					window.location.href = buttonhref;
				});
			}
		})
	}
*/
	
	//during Goals
	function displayInfoSpace(element){
		var infoSpaceElement = document.getElementById(element.id + "-information"),
			allInfoSpaceElements = document.getElementsByClassName("effort-v-impact-info-section");
		
		if(infoSpaceElement.style.display == "block"){
			infoSpaceElement.style.display = "none";
			element.classList.remove("infoSpaceHighlight");
		}else{
			for(var i=0; i < allInfoSpaceElements.length; i++){
				document.getElementById(allInfoSpaceElements[i].id).style.display = "none";
			}
			var units = document.getElementsByClassName("unit");
			
			for(var i=0; i < units.length; i++){
				document.getElementById(units[i].id).classList.remove("infoSpaceHighlight");
			}
			element.classList.add("infoSpaceHighlight");
			infoSpaceElement.style.display = "block";
		}
	}
	
	if(typeof graphData !== 'undefined'){
		
		var spaceAvailability = [],
			infoSpaces = document.getElementsByClassName("effort-v-impact-info-section");
		
		for(var i=0; i < infoSpaces.length; i++){
			infoSpaces[i].innerHTML = "";
		}
		
		for(var e=10; e > 0; e--){
			for(var i=1; i < 11; i++){
				document.getElementById(i + "-impact-" + e + "-effort").addEventListener("click", function(){ displayInfoSpace(this) });
			}
		}
		
		for(var i=0; i < graphData.length; i++){
			var data = graphData[i],
				space = data.impact + "-impact-" + data.effort + "-effort",
				infoSpace = document.getElementById( data.impact + "-impact-" + data.effort + "-effort-information" ),
				spaceAvailable = 1,
				spaceColour = getRandomColor();
			
			if( data.impact != 0 || data.effort != 0 ){
				if(spaceAvailability.length > 0){
					for(var sa=0; sa < spaceAvailability.length; sa++ ){
						if( space + "-" + spaceAvailable == spaceAvailability[sa] ){
							spaceAvailable++;
						}
					}	
				}
				
				space = space + "-" + spaceAvailable;
				spaceAvailability.push(space);
				
				var graphPosition = document.getElementById(space);
				
				//graphPosition.style = "background: " + spaceColour +";";
				graphPosition.style.background = "#88e0ff";
				
				//infoSpace
				var infoSpaceHTMl;
				
				infoSpaceHTMl = "<div class='infoSpace ";
				if(data.statementResults < 2){
					infoSpaceHTMl += "statementScoreRed'>";
				}else if(data.statementResults < 4 && data.statementResults > 1 ){
					infoSpaceHTMl += "statementScoreOrange'>";
				}else if(data.statementResults < 7 && data.statementResults > 3 ){
					infoSpaceHTMl += "statementScoreGreen'>";
				}else if( data.statementResults > 6 ){
					infoSpaceHTMl += "statementScoreGold'>";
				}
				infoSpaceHTMl += "<div class='infoSpaceTitleArea'><h3>" + data.name + "</h3><p class='statementScore'>Score: " + data.statementResults + "</p><img alt='' src='data:image/svg+xml;base64,PHN2ZyBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCAzMjAgNTEyIiB2aWV3Qm94PSIwIDAgMzIwIDUxMiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJtMTUxLjUgMzQ3LjgtMTQ4LTE0Ni44Yy00LjctNC43LTQuNy0xMi4zIDAtMTdsMTkuOC0xOS44YzQuNy00LjcgMTIuMy00LjcgMTcgMGwxMTkuNyAxMTguNSAxMTkuNy0xMTguNWM0LjctNC43IDEyLjMtNC43IDE3IDBsMTkuOCAxOS44YzQuNyA0LjcgNC43IDEyLjMgMCAxN2wtMTQ4IDE0Ni44Yy00LjcgNC43LTEyLjMgNC43LTE3IDB6IiBmaWxsPSIjZmZmIi8+PC9zdmc+' /></div><div class='infoSpaceTextArea'><h4>" + data.statementTexts + "</h4>";
				
				if(data.strengthsComment != ""){
					infoSpaceHTMl += "<h5>Strengths</h5><p>" + data.strengthsComment + "</p>";
				}
				
				if(data.areasForDevelopmentComment != ""){
					infoSpaceHTMl += "<h5>Areas For Development</h5><p>" + data.areasForDevelopmentComment + "</p>";
				}
				
				if(data.potentialActionsComment != ""){
					infoSpaceHTMl += "<h5>Potential Actions</h5><p>" + data.potentialActionsComment + "</p>";
				}
				
				infoSpaceHTMl += "</div></div>";
				
				infoSpace.innerHTML += infoSpaceHTMl;
			}
		}
		
		function displaySpaceInfo(){
			if( this.classList.contains("infoSpaceOpen") ){
				this.classList.remove("infoSpaceOpen");
			}else{
				for(var i=0; i < allInfoSpaces.length; i++){
					allInfoSpaces[i].classList.remove("infoSpaceOpen");
				}
				this.classList.add("infoSpaceOpen");
			}
		}
		
		//ADD eventlistener to each infoSpace
		var allInfoSpaces = document.getElementsByClassName('infoSpace');
		for(var i=0; i < allInfoSpaces.length; i++){
			allInfoSpaces[i].addEventListener("click", displaySpaceInfo, false);
		}
	}
	
	//IF DASHBOARD
	if( $( "#pathway-health-check-dashboard" ).length ){
		dashboard.init();
	}
	
}


window.onload = run;